export default {
buildTime: "2024-04-03T16:03:25Z",
commit: "08237be917384a1e968fb21041261f2a0eb3b1ad",
npmInfo:
{
  mashlib: '1.8.10',
  npm: '8.19.4',
  node: '16.20.2',
  v8: '9.4.146.26-node.26',
  uv: '1.43.0',
  zlib: '1.2.11',
  brotli: '1.0.9',
  ares: '1.19.1',
  modules: '93',
  nghttp2: '1.47.0',
  napi: '8',
  llhttp: '6.0.11',
  openssl: '1.1.1v+quic',
  cldr: '41.0',
  icu: '71.1',
  tz: '2022f',
  unicode: '14.0',
  ngtcp2: '0.8.1',
  nghttp3: '0.7.0'
}
};
